<template>
    <div>
        <ValidationObserver ref="detailFormValid">
            <b-row>
                <b-col cols="12">
                    <h6> {{ innerValue.name }} {{ innerValue.surname }} </h6>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="academic_year" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox :disabled="disabled" :validate-error="errors[0]"
                                v-model="innerValue.academic_year">
                            </academic-years-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="cgpa" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('cgpa')">
                            <b-form-input type="text" :disabled="disabled" v-model="innerValue.cgpa">
                            </b-form-input>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="cgpa" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('class')">
                            <classes-selectbox v-model="innerValue.class" :disabled="disabled"
                                :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('faculty_name')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(innerValue.faculty_name) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('program_name')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(innerValue.program_name) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('semester_count')">
                        <template v-if="!semester.status">
                            <b-input-group>
                                <div class="label-as-input input-edit-icon d-flex flex-row justify-content-between">{{
                                    isNotNullValue(innerValue.semester_count) }}
                                    <i class="ri-edit-line" style="font-size: 20px; cursor: pointer;"
                                        @click="semester.status = !semester.status"></i>
                                </div>
                            </b-input-group>
                        </template>
                        <template v-else>
                            <b-input-group class="mb-3">
                                <b-form-input v-model="semester.value">
                                </b-form-input>
                                <b-input-group-append class="border border-light px-2">
                                    <i class="ri-save-line" style="font-size: 20px; cursor: pointer;"
                                        @click="saveSemester"></i>
                                </b-input-group-append>
                            </b-input-group>
                        </template>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('prep_status')">
                        <b-input-group>
                            <div class="label-as-input">{{ getLocaleText(innerValue.prep_status, 'name') }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('registration_type')">
                        <b-input-group>
                            <div class="label-as-input">{{ getLocaleText(innerValue, 'registration_type_text') }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('student_number')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(innerValue.student_number) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4" v-if="innerValue.student_program.student.person.nationality_code != 'TR'">
                    <b-form-group :label="$t('equivalence_status')">
                        <b-input-group>
                            <div class="label-as-input">{{ innerValue.equivalence_status ? $t('yes') : $t('no') }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('success_rate1')">
                        <b-input-group>
                            <div class="label-as-input">{{ innerValue.success_rate || "-" }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col cols="12">
                    <h6 class="mt-4 mb-2">{{ $t('documents') }}</h6>
                </b-col>
                <b-col cols="12">
                    <div class="d-flex flex-wrap">
                        <b-card :sub-title="getLocaleText(doc, 'name')" class="mr-2 mt-2" style="min-width: 18rem;"
                            v-for="(doc, index) in innerValue.documents" :key="index">

                            <b-card-text>{{ $t('status') }}: {{ $t(`status_${doc.status}`) }}</b-card-text>
                            <template #footer>
                                <div class="d-flex flex-row justify-content-around">
                                    <a class="card-link" @click="showDoc(doc)">{{ $t('show') }}</a>
                                    <b-link v-if="!['approved', 'waiting'].includes(doc.status)"
                                        class="card-link text-success" @click="approveDoc(doc, 'approved')">
                                        {{ $t('approve') }}
                                    </b-link>
                                    <b-link v-if="!['declined', 'waiting'].includes(doc.status)"
                                        class="card-link text-danger ml-3" @click="approveDoc(doc, 'declined')">
                                        {{ $t('reject') }}
                                    </b-link>
                                </div>
                            </template>
                        </b-card>
                        <b-card :sub-title="$t(doc)" class="mr-2 mt-2" style="min-width: 18rem;"
                            v-for="(doc, index) in insideDocument" :key="index">
                            <template #footer>
                                <div class="d-flex flex-row justify-content-around">
                                    <a class="card-link" @click="downloadInsideDocument(doc)">{{ $t('show') }}</a>
                                </div>
                            </template>
                        </b-card>
                    </div>
                </b-col>

            </b-row>
        </ValidationObserver>
        <b-row class="mb-2">
            <b-col cols="6">
                <span class="font-weight-medium">
                    {{ $t("your_preferences").toUpper() }}
                </span>
            </b-col>
            <b-col cols="6">
                <button class="btn btn-primary float-right" @click="preferenceModalShow">{{ $t('add_preference1')
                }}</button>
            </b-col>

        </b-row>

        <b-table :empty-filtered-text="$t('no_result')" :empty-text="$t('no_result')" bordered
            :items="doubleMajorPreferences" :fields="doubleMajorPreferencesFields" show-empty
            class="mb-4 table-dropdown no-scrollbar border rounded">

            <template #cell(action)="row">
                <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                        <i class="ri-more-fill"></i>
                    </template>
                    <b-dropdown-item @click="restorePreference(row.item)">
                        <div class="d-flex align-items-center">
                            <i class="ri-refresh-line mr-2"></i>
                            <span>{{ $t('preference_restore') }}</span>
                        </div>
                    </b-dropdown-item>
                    <b-dropdown-item @click="updateModalShow(row.item)">
                        <div class="d-flex align-items-center">
                            <i class="ri-pencil-line mr-2"></i>
                            <span>{{ $t('status_update') }}</span>
                        </div>
                    </b-dropdown-item>
                    <b-dropdown-item @click="deletePreference(row.item)">
                        <div class="d-flex align-items-center">
                            <i class="ri-delete-bin-line mr-2"></i>
                            <span>{{ $t('delete') }}</span>
                        </div>
                    </b-dropdown-item>
                </b-dropdown>
            </template>
            <template #cell(index)="row">
                <div class="d-flex align-items-center justify-content-around rank-container">
                    <template v-if="!rankStatus[row.item.index]?.status">
                        <b-input-group>
                            <div class="label-as-input input-edit-icon d-flex flex-row justify-content-between">
                                {{ isNotNullValue(row.item.rank) }}
                                <i class="ri-edit-line" style="font-size: 20px; cursor: pointer;"
                                    @click="saveRank(row.item.index, true)"></i>
                            </div>
                        </b-input-group>
                    </template>
                    <template v-else>
                        <ValidationObserver :ref="`prefChange-${row.item.index}`">
                            <ValidationProvider :name="`rank-${row.item.index}`" rules="required|max_value:3|min_value:1"
                                v-slot="{ valid, errors }">
                                <b-input-group class="mb-3">
                                    <b-form-input v-model.number="rankStatus[row.item.index].rank"
                                        :state="errors[0] ? false : null">
                                    </b-form-input>
                                    <b-input-group-append class="border border-light px-2">
                                        <i class="ri-save-line" style="font-size: 20px; cursor: pointer;"
                                            @click="saveRank(row.item.index, false)"></i>
                                    </b-input-group-append>
                                </b-input-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </ValidationProvider>
                        </ValidationObserver>
                    </template>
                </div>
            </template>
            <template #cell(faculty_program)="row">
                {{ row.item.faculty_name }} <br />{{ row.item.program_name }}
            </template>
            <template #cell(result)="row">
                <template v-if="!row.item.result">-</template>
                <template v-else>
                    <span class="badge" :class="['placed', 'other_preference_placed'].includes(row.item.result) ? 'badge-success'
                        : row.item.result == 'not_placed' ? 'badge-danger' : 'badge-warning'">
                        {{ $t(`application_result.${row.item.result}`) }}
                    </span>
                </template>
            </template>
            <template #cell(status)="row">
                {{ row.item.status_text }}
            </template>
            <template #cell(explanation)="row">
                <div class="d-flex justify-content-center">
                    {{ row.item.explanation || "-" }}
                </div>
            </template>
        </b-table>
        <CommonModal ref="updateModal" size="md" :onHideOnlyX="true">
            <template v-slot:CommonModalTitle>
                {{ $t("status_update").toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <ValidationObserver ref="updateModalValid">
                    <b-row>
                        <b-col cols="12">
                            <ValidationProvider name="status" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('status')">
                                    <b-form-select :validate-error="errors[0]" v-model="update.status"
                                        :state="errors[0] ? false : null"
                                        :options="[{ text: $t('declined'), value: 'student_affairs_declined' }, { text: $t('status_approved'), value: 'approved' }]">
                                    </b-form-select>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />

                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" v-show="update.status == 'student_affairs_declined'">
                            <ValidationProvider name="explanation"
                                :rules="`${update.status == 'student_affairs_declined' ? 'required' : ''}`"
                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('explanation')">
                                    <b-form-input :validate-error="errors[0]" v-model="update.explanation" type="text"
                                        :state="errors[0] ? false : null">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="6">
                            <button class="btn btn-secondary" @click="$refs.updateModal.$refs.commonModal.hide()">
                                {{ $t("close") }}
                            </button>
                        </b-col>
                        <b-col cols="6">
                            <button class="btn btn-primary float-right" @click="updateStatus">
                                {{ $t("save") }}
                            </button>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </template>
        </CommonModal>
        <CommonModal ref="updateApplicationModal" size="md" :onHideOnlyX="true">
            <template v-slot:CommonModalTitle>
                {{ $t("status_update").toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <ValidationObserver ref="updateApplicationModalValid">
                    <b-row>
                        <b-col cols="12">
                            <ValidationProvider name="status" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('status')">
                                    <b-form-select :validate-error="errors[0]" v-model="updateApplicationObj.status"
                                        :state="errors[0] ? false : null"
                                        :options="[{ text: $t('waiting_prep_school'), value: 'waiting_prep_school' }, { text: $t('waiting_academic_unit_office'), value: 'waiting_academic_unit_office' }]">
                                    </b-form-select>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />

                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" v-show="updateApplicationObj.status === 'waiting_prep_school'">
                            <p class="text-start mb-3">{{ $t('exams_to_take') }}</p>

                            <div class="d-flex flex-row justify-content-start mb-2">
                                <b-form-group>
                                    <b-form-checkbox v-model="updateApplicationObj.exams.en" :value="true">
                                        {{ $t('en') }}
                                    </b-form-checkbox>
                                </b-form-group>

                                <b-form-group class="ml-5">
                                    <b-form-checkbox v-model="updateApplicationObj.exams.tr" :value="true">
                                        {{ $t('tr') }}
                                    </b-form-checkbox>
                                </b-form-group>
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <button class="btn btn-secondary"
                                @click="$refs.updateApplicationModal.$refs.commonModal.hide()">
                                {{ $t("close") }}
                            </button>
                        </b-col>
                        <b-col cols="6">
                            <button class="btn btn-primary float-right" @click="updateApplication">
                                {{ $t("save") }}
                            </button>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </template>
        </CommonModal>
        <CommonModal ref="updateRankModal" size="md" :onHideOnlyX="true">
            <template v-slot:CommonModalTitle>
                {{ $t("update").toUpper() }} {{ $t("rank").toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <b-row>
                    <b-col cols="12">
                        <ValidationObserver ref="rejectFormValid">
                            <ValidationProvider name="cancel_reason" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('cancel_reason')">
                                    <b-form-select :validate-error="errors[0]" :options="rankOptions"
                                        v-model="updateRank.rank">
                                    </b-form-select>
                                </b-form-group>
                            </ValidationProvider>
                        </ValidationObserver>
                    </b-col>
                    <b-col cols="6">
                        <button class="btn btn-secondary" @click="$refs.declineFormModal.$refs.commonModal.hide()">
                            {{ $t("close") }}
                        </button>
                    </b-col>
                    <b-col cols="6">
                        <button class="btn btn-primary float-right" @click="rankSend">
                            {{ $t("save") }}
                        </button>
                    </b-col>
                </b-row>
            </template>
        </CommonModal>
        <CommonModal ref="preferenceModal" size="lg" :onHideOnlyX="true">
            <template v-slot:CommonModalTitle>
                {{ $t("add_preference").toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <ValidationObserver ref="preferenceModalValidate">
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('faculty')">
                                <ValidationProvider name="faculty_code" rules="required" v-slot="{ valid, errors }">
                                    <faculty-selectbox v-model="preference.faculty_code" :validate-error="errors[0]" />
                                </ValidationProvider>
                            </b-form-group>

                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('program')">
                                <ValidationProvider name="program_code" rules="required" v-slot="{ valid, errors }">
                                    <multiselect :placeholder="$t('select')" v-model="preference.program_code"
                                        :options="programOptions" :class="errors[0] ? 'is-invalid' : ''" label="text"
                                        track-by="value" :close-on-select="true" :clear-on-select="true" :select-label="''"
                                        :selected-label="''" :deselect-label="''">
                                        <span slot="noOptions">{{ $t('no_options') }}</span>
                                        <span slot="noResult">{{ $t('no_result') }}</span>
                                    </multiselect>
                                    <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]" />
                                </ValidationProvider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('rank')">
                                <ValidationProvider name="rank" rules="required" v-slot="{ valid, errors }">
                                    <b-select :options="[1, 2, 3]" v-model="preference.rank"
                                        :class="errors[0] ? 'is-invalid' : ''" />
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </ValidationProvider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                        </b-col>
                        <b-col cols="6">
                            <button class="btn btn-secondary" @click="$refs.preferenceModal.$refs.commonModal.hide()">
                                {{ $t("close") }}
                            </button>
                        </b-col>
                        <b-col cols="6">
                            <button class="btn btn-primary float-right" @click="preferenceAdd">
                                {{ $t("add") }}
                            </button>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </template>
        </CommonModal>
        <b-row>
            <b-col>
                <button class="btn btn-primary" @click="updateApplicationModalShow">
                    {{ $t('application_status_update') }}
                </button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import DoubleMajorApplicationService from "@/services/DoubleMajorApplicationService";
import StudentDocumentService from "@/services/StudentDocumentService";

import { EventBus } from '@/main'

export default {
    name: "DoubleMajorApplicationAdminForm",
    props: {
        minorApplication: {
            type: Object,
        },
        type: {
            type: String,
            default: "",
        },
        value: {
            type: Object,
            default: () => { },
        },
    },
    watch: {
        'innerValue.double_major_preferences': {
            handler(val) {
                if (val?.length > 0) {
                    this.rankStatus = val.map(item => ({ rank: item.rank, id: item.id, status: false }))
                }
            },
            deep: true,
            immediate: true
        },
        'preference.faculty_code': {
            handler(newValue, oldValue) {
                if (newValue != oldValue) {
                    this.getProgram(newValue)
                }
            }
        },
    },
    computed: {
        innerValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
        rankOptions() {
            return this.innerValue.double_major_preferences.map(item => ({ text: item.rank, value: item.rank }))
        },
        disabled() {
            return ["show", "delete"].includes(this.type)
        },
        doubleMajorPreferences: {
            get() {
                return this.innerValue.double_major_preferences.map((item, index) => ({ ...item, index }))
            },
            set(val) {
                this.$emit("input", { ...this.value, double_major_preferences: val });
            },
        }
    },
    data() {
        return {
            formLoading: false,
            updateRank: { rank: null },
            oldRank: null,
            rankStatus: [],
            insideDocument: ['student_transcript',
                'student_english_language_proficiency_document'],
            semester: {
                status: false,
                value: null
            },
            update: {
                status: "",
                explanation: "",
            },
            updateApplicationObj: {
                exams: {
                    en: true
                }
            },
            preference: {},
            programOptions: [],
            documentLoading: false,
            updatable: [],
            minorApplicationId: null,
            doubleMajorPreferencesFields: [
                {
                    key: "action",
                    label: this.$t("action").toUpper(),
                    tdClass: "text-center align-middle",
                    thClass: "text-center",
                    sortable: false,
                },
                {
                    key: "index",
                    label: this.$t("rank").toUpper(),
                    tdClass: "text-center align-middle",
                    thClass: "text-center",
                    sortable: false,
                },
                {
                    key: "faculty_program",
                    label: this.$t("faculty").toUpper(),
                    tdClass: "align-middle",
                    sortable: false,
                },
                // {
                //     key: "prep_status",
                //     label: this.$t("prep_status").toUpper(),
                //     tdClass: "align-middle text-center",
                //     thClass: "text-center",
                //     sortable: false,
                // },
                {
                    key: "result",
                    label: this.$t("result").toUpper(),
                    tdClass: "align-middle",
                },
                {
                    key: "status",
                    label: this.$t("status").toUpper(),
                    tdClass: "align-middle",
                    sortable: false,
                },
                {
                    label: this.$t("explanation").toUpper(),
                    key: "explanation",
                },
            ],

            extraInfo: false,
        };
    },
    methods: {
        async restorePreference(row) {
            const result = await this.$swal
                .fire({
                    text: this.$t("are_you_sure_to_restore_preference"),
                    showCancelButton: true,
                    confirmButtonText: this.$t("yes"),
                    cancelButtonText: this.$t("no"),
                    icon: "question",
                })

            if (!result.isConfirmed) {
                return
            }

            const response = await DoubleMajorApplicationService.studentAffairsRestorePreference(row.id)
                .catch(e => this.$toast.error(this.$t('api.' + e.data.message)))

            if (response.data.success) {
                this.$emit('getItem', this.innerValue.id)
            }
            this.$toast.success(this.$t('preference_restore_success'));
        },
        async saveRank(index, status) {
            if (status) {
                this.rankStatus[index].status = status
                return
            }

            const valid = await this.$refs[`prefChange-${index}`].validate()
            if (!valid) {
                return
            }

            const newRank = this.rankStatus[index].rank
            const oldRank = this.doubleMajorPreferences[index].rank
            const findIndex = this.doubleMajorPreferences.findIndex(i => i.rank === newRank)
            if (findIndex < 0) {
                return
            }

            this.rankStatus[findIndex].rank = oldRank
            if (oldRank !== newRank) {
                const data = {
                    student_program_id: this.innerValue.student_program_id,
                    rank: this.rankStatus.map(i => ({ ...i, double_major_preference_id: i.id }))
                }
                const res = await DoubleMajorApplicationService.studentAffairsChangePreferenceRanks(data).catch((e) =>
                    this.$toast.error(this.$t("api." + e.data.message))
                );
                if (res.data.success) {
                    this.$emit('getItem', this.innerValue.id)
                    this.rankStatus[index].status = status
                }
                this.$toast.success(this.$t('api.' + res.data.message));
            }

        },
        async saveSemester() {
            const data = {
                double_major_id: this.innerValue.id,
                semester_count: Number(this.semester.value)
            }
            const res = await DoubleMajorApplicationService.studentAffairsUpdateSemesterCount(data).catch((e) =>
                this.$toast.error(this.$t("api." + e.data.message))
            );

            if (res.data.success) {
                this.$emit('getItem', this.innerValue.id)
                this.semester.status = false
            }
            this.$toast.success(this.$t('api.' + res.data.message));
        },
        async showDoc(file) {
            const type = file.name == 'ÖSYM Sonuç Belgesi' ? 'osym_result_document' : file.name == 'ÖSYM Yerleştirme Belgesi' ? 'osym_placement_document' : ''
            const res = await DoubleMajorApplicationService.studentAffairsDownloadDocument(this.innerValue.id, type).catch((e) =>
                this.$toast.error(this.$t("api." + e.data.message))
            );

            EventBus.$emit('pdfViewerModalShow', { pdfFileData: res.data, pdfFileName: file.filename });
            // this._downloadFile(res, file.filename)

        },
        async approveDoc(file, status) {
            const response = await this.$swal({
                text: status == 'approved' ? this.$t('are_you_sure_to_approve') : status == 'declined' ? this.$t('are_you_sure_to_reject') : '',
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
            if (response.isConfirmed !== true) {
                return
            }

            const type = file.name == 'ÖSYM Sonuç Belgesi' ? 'osym_result_document' : file.name == 'ÖSYM Yerleştirme Belgesi' ? 'osym_placement_document' : ''
            const data = {
                double_major_id: this.innerValue.id,
                document_type: type,
                status
            }
            const res = await DoubleMajorApplicationService.studentAffairsApproveDocument(data).catch((e) =>
                this.$toast.error(this.$t("api." + e.data.message))
            );

            if (res.data.success) {
                this.$emit('getItem', this.innerValue.id)
            }
            this.$toast.success(this.$t('api.' + res.data.message));
        },
        async deletePreference(row) {
            const res = await this.$swal
                .fire({
                    text: this.$t("are_you_sure_to_delete"),
                    showCancelButton: true,
                    confirmButtonText: this.$t("yes"),
                    cancelButtonText: this.$t("no"),
                    icon: "question",
                })

            if (!res.isConfirmed) {
                return
            }

            const data = {
                student_program_id: this.innerValue.student_program_id,
                double_major_preference_id: row.id
            }
            const response = await DoubleMajorApplicationService.studentAffairsDeletePreference(data)
                .catch(e => this.$toast.error(this.$t('api.' + e.data.message)))
            this.$emit('getItem', this.innerValue.id)
            if (response.data.success) {
                this.$toast.success(this.$t('preference_delete'));
            }

        },
        preferenceModalShow() {
            this.$refs.preferenceModal.$refs.commonModal.show()
        },
        async preferenceAdd() {
            const valid = await this.$refs['preferenceModalValidate'].validate()
            if (!valid) {
                return
            }

            const data = {
                program_code: this.preference.program_code.value,
                rank: this.preference.rank,
                student_program_id: this.innerValue.student_program_id,
            }
            const response = await DoubleMajorApplicationService.studentAffairsAddPreference(data)
                .catch(e => this.$toast.error(this.$t('api.' + e.data.message)))


            if (response.data.success) {
                this.$refs.preferenceModal.$refs.commonModal.hide()
                this.$emit('getItem', this.innerValue.id)
                this.preference = {}
            }
            this.$toast.success(this.$t('success'));
        },
        updateModalShow(row) {
            this.update = {
                double_major_id: row.double_major_id,
                double_major_preference_id: row.id,
            }
            this.$refs.updateModal.$refs.commonModal.show()
        },
        async updateStatus() {
            const valid = await this.$refs['updateModalValid'].validate()

            if (!valid) {
                return
            }

            if (this.update.status === 'approved') {
                delete this.update.explanation
            }
            const response = await DoubleMajorApplicationService.studentAffairsApprovePreference(this.update)
                .catch(e => this.$toast.error(this.$t('api.' + e.data.message)))


            if (response.data.success) {
                this.$refs.updateModal.$refs.commonModal.hide()

                this.$emit('getItem', this.innerValue.id)
                this.update = {}
            }
            this.$toast.success(this.$t('api.' + response.data.message));
        },
        rankSend() {
            const index = this.innerValue.double_major_preferences.findIndex(item => item.rank === this.updateRank.rank)

            if (index > -1) {
                this.innerValue.double_major_preferences[index].rank = this.oldRank
            }
        },
        async updateApplicationModalShow() {
            this.updateApplicationObj.exams.en = true
            this.updateApplicationObj.exams.tr = false

            this.$refs.updateApplicationModal.$refs.commonModal.show()
        },
        async updateApplication() {
            const valid = await this.$refs['updateApplicationModalValid'].validate()
            if (!valid) {
                return
            }

            if (!this.updateApplicationObj.exams.tr || this.updateApplicationObj.status !== 'waiting_prep_school') {
                delete this.updateApplicationObj.exams.tr
            }
            if (!this.updateApplicationObj.exams.en || this.updateApplicationObj.status !== 'waiting_prep_school') {
                delete this.updateApplicationObj.exams.en
            }

            const data = {
                double_major_id: this.innerValue.id, status: this.updateApplicationObj.status,
                exams: Object.keys(this.updateApplicationObj.exams)
            }

            const response = await DoubleMajorApplicationService.studentAffairsApproveApplication(data)
                .catch(e => this.$toast.error(this.$t('api.' + e.data.message)))

            this.$emit('getItem', this.innerValue.id)
            this.$emit('close')
            this.$refs.updateApplicationModal.$refs.commonModal.hide()
            this.$toast.success(this.$t('api.' + response.data.message));
        },
        downloadDocument(name) {
            this.documentLoading = true
            if (name === "student_transcript") {
                StudentDocumentService.downloadTranscript(this.innerValue.student_program_id, this.$i18n.locale)
                    .then(response => {
                        let fileName = this.innerValue.student_program_id + '-' + this.$t('transcript') + '-' + this.$i18n.locale + '.pdf'
                        EventBus.$emit('pdfViewerModalShow', { pdfFileData: response.data, pdfFileName: fileName });
                    }).finally(() => {
                        this.documentLoading = false
                    })
            } else if (name === "student_english_language_proficiency_document") {
                StudentDocumentService.downloadLanguageProficiency(this.innerValue.student_program_id, this.$i18n.locale)
                    .then(response => {
                        let fileName = this.innerValue.student_program_id + '-' + this.$t('file_name_language_proficiency') + '-' + this.$i18n.locale + '.pdf'
                        EventBus.$emit('pdfViewerModalShow', { pdfFileData: response.data, pdfFileName: fileName });
                    })
                    .catch(e => {
                        this.showErrors(e, null, true)
                    }).finally(() => {
                        this.documentLoading = false
                    })
            }

        },
        getProgram(facultyCode) {
            this.programOptions = [];
            if (facultyCode) {
                DoubleMajorApplicationService.programsAdmin(facultyCode, this.innerValue.student_program_id)
                    .then(response => {
                        let data = response.data.data;

                        data.map((item) => {
                            this.programOptions.push({
                                value: item.program_code,
                                text: this.getLocaleText(item, 'program_name') + ' (' + this.$t('quota').toUpper() + ': ' + item.quota + ')'
                            })
                        })
                    })
                    .catch(e => {
                        this.showErrors(e);
                    })
            }
        },
        downloadInsideDocument(name) {
            this.documentLoading = true
            if (name === "student_transcript") {
                StudentDocumentService.downloadTranscript(this.innerValue.student_program_id, this.$i18n.locale)
                    .then(response => {
                        let fileName = this.innerValue.student_program_id + '-' + this.$t('transcript') + '-' + this.$i18n.locale + '.pdf'
                        EventBus.$emit('pdfViewerModalShow', { pdfFileData: response.data, pdfFileName: fileName });
                    }).finally(() => {
                        this.documentLoading = false
                    })
            } else if (name === "student_english_language_proficiency_document") {
                StudentDocumentService.downloadLanguageProficiency(this.innerValue.student_program_id, this.$i18n.locale)
                    .then(response => {
                        let fileName = this.innerValue.student_program_id + '-' + this.$t('file_name_language_proficiency') + '-' + this.$i18n.locale + '.pdf'
                        EventBus.$emit('pdfViewerModalShow', { pdfFileData: response.data, pdfFileName: fileName });
                    })
                    .catch(e => {
                        this.showErrors(e, null, true)
                    }).finally(() => {
                        this.documentLoading = false
                    })
            }
        },
    },
    mounted() {
        this.semester.value = this.innerValue.semester_count
    },
};
</script>

<style lang="scss">
.rank-container {
    i {
        width: 24px;
        height: 24px;

        &::before {
            width: 40px;
            height: 40px;

            width: 24px;
            height: 24px;
        }
    }
}

.input-edit-icon {
    i {
        transition: all 0.2s linear;

        &:hover {
            transform: scale(1.3);
        }
    }
}
</style>