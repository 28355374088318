<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('double_major_applications') + ' - ' + $t('student_affairs')"
                    @filter-div-status="datatable.filterStatus = $event" :isColumns="true">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('double_major_applications') + ' - ' + $t('student_affairs')"
                    @filter-div-status="datatable.filterStatus = $event" :isColumns="true">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                :exportExcel="true" @exportExcel="exportExcel(datatable.queryParams)">
                <b-row>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('period') + ' / ' + $t('semester')">
                            <semesters-selectbox v-model="datatable.queryParams.filter.semester_id
                                "></semesters-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input v-model="datatable.queryParams.filter.student_number
                                " />
                        </b-form-group>
                    </b-col>

                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.to_faculty
                                " />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('surname')">
                            <b-form-input v-model="datatable.queryParams.filter.surname" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">

                        <b-form-group :label="$t('program')">
                            <program-selectbox :faculty_code="datatable.queryParams.filter.to_faculty
                                " v-model="datatable.queryParams.filter.to_program" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('status')">
                            <parameter-selectbox code="double_major_statuses"
                                v-model="datatable.queryParams.filter.status" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('result')">
                            <b-form-select v-model="datatable.queryParams.filter.result" :options="[{ text: $t('application_result.placed'), value: 'placed' },
                            { text: $t('application_result.not_placed'), value: 'not_placed' }]" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                        <b-form-group :label="$t('archive')">
                            <b-form-select v-model="datatable.queryParams.filter.is_archived" :options="[{ text: $t('all'), value: '0,1' },
                            { text: $t('yes'), value: '1' },{ text: $t('no'), value: '0' }]" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable">
            </datatable>
            <CommonModal ref="detailFormModal" size="xxl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
                <template v-slot:CommonModalTitle>
                    {{ $t("detail").toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <detail-form v-model="formData" :type="formType" @getItem="getItem"
                        @close="$refs.detailFormModal.$refs.commonModal.hide()" v-if="formProcess == 'detail'" />
                </template>
            </CommonModal>

            <CommonModal ref="declineFormModal" size="md" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t("reject").toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <b-row>
                        <b-col cols="12">
                            <ValidationObserver ref="rejectFormValid">
                                <ValidationProvider name="cancel_reason" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('cancel_reason')">
                                        <b-form-input :validate-error="errors[0]" v-model="explanation">
                                        </b-form-input>
                                    </b-form-group>
                                </ValidationProvider>
                            </ValidationObserver>
                        </b-col>
                        <b-col cols="6">
                            <button class="btn btn-secondary" @click="$refs.declineFormModal.$refs.commonModal.hide()">
                                {{ $t("close") }}
                            </button>
                        </b-col>
                        <b-col cols="6">
                            <button class="btn btn-danger float-right" @click="deleteConfirm">
                                {{ $t("reject") }}
                            </button>
                        </b-col>
                    </b-row>
                </template>
            </CommonModal>
            <CommonModal ref="messsageFormModal" size="md" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t("message_send").toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <b-row>
                        <b-col cols="12">
                            <ValidationObserver ref="messageFormValid">
                                <ValidationProvider name="message" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('message')">
                                        <b-form-input v-model="messageObj.message" :state="errors[0] ? false : null">
                                        </b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>

                                </ValidationProvider>
                            </ValidationObserver>
                        </b-col>
                        <b-col cols="12">
                            <button class="btn btn-primary float-right" @click="sendMessage">
                                {{ $t("send") }}
                            </button>
                        </b-col>
                    </b-row>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Component
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";

// Pages
import DetailForm from "./DetailForm";

// Service
import DoubleMajorApplicationService from "@/services/DoubleMajorApplicationService";

// Other
import qs from "qs";

import { exportExcel } from "../function"

export default {
    name: "DoubleMajorApplicationsAdmin",
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        SemestersSelectbox,
        FacultySelectbox,
        ProgramSelectbox,
        ParameterSelectbox,

        DetailForm,
    },
    metaInfo() {
        return {
            title: this.$t("double_major_applications") + " - " + this.$t('student_affairs'),
        };
    },
    data() {
        return {
            exportExcel,
            formId: -1,
            formData: {},
            formType: "",
            explanation: "",
            rejectId: -1,
            formProcess: null,
            messageObj: {},
            update: {
                double_major_id: -1,
                status: null,
            },
            updateOptions: [
                { text: this.$t('student_affairs'), value: 'waiting' },
                { text: this.$t('minor_status_obj.waiting_academic_unit_office'), value: 'waiting_academic_unit_office' }
            ],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("detail"),
                                class: "ri-edit-box-line",
                                callback: (row) => {
                                    this.showDetail(row.id, row);
                                },
                            },
                            {
                                text: this.$t("message_send"),
                                class: "ri-mail-send-line",
                                callback: (row) => {
                                    this.messageModalOpen(row);
                                },
                            },
                            {
                                text: this.$t("application_status_reset"),
                                class: "ri-refresh-line",
                                callback: (row) => {
                                    this.updateShow(row);
                                },
                            },
                            {
                                text: this.$t("reject"),
                                class: "ri-delete-bin-2-line",
                                callback: (row) => {
                                    this.delete(row.id);
                                },
                            },
                        ],
                    },
                    {
                        label: this.$t("id"),
                        field: "id",
                        sortable: false,
                        hidden: true,
                    },
                    {
                        label: this.$t("period") + " / " + this.$t("semester"),
                        field: "semester",
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return (
                                row.academic_year +
                                "<br>" +
                                this.getSemester(value)
                            );
                        },
                    },
                    {
                        label: this.$t("student"),
                        field: "student_number",
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return (
                                row.student_number +
                                "<br>" +
                                row.name +
                                " " +
                                row.surname +
                                "<br>" +
                                row.faculty_name +
                                "<br>" +
                                row.program_name
                            );
                        },
                    },
                    {
                        label: this.$t("class"),
                        field: "class",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t("registration_date"),
                        field: "registration_date",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t("prep_status"),
                        field: "prep_status",
                        formatFn: (value) => {
                            return this.getLocaleText(value, 'name')
                        },
                        sortable: false,
                        hidden: false,
                    },

                    {
                        label: this.$t("preferences").toUpper(),
                        field: "double_major_preferences",
                        sortable: false,
                        hidden: false,
                        formatFn: (value) => {
                            let html = "";
                            if (value.length > 0) {
                                html += '<ol class="pl-2 m-0">';
                                value.forEach((item) => {
                                    html +=
                                        "<li>" +
                                        item.faculty_name +
                                        "<br>" +
                                        item.program_name +
                                        "</li>";
                                });
                                html += "</ol>";
                            }

                            return html;
                        },
                    },
                    {
                        label: this.$t("result").toUpper(),
                        field: "result",
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            if (!row.result) return '-'
                            let variant = ['placed'].includes(row.result) ? 'badge-success'
                                : row.result == 'not_placed' ? 'badge-danger' : 'badge-warning'

                            return (
                                `<span class="badge ${variant}">${this.$t(`application_result.${row.result}`)}</span>`);
                        },
                    },
                    {
                        label: this.$t("status").toUpper(),
                        field: "status",
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            let variant = "badge-light";
                            if (value.includes("waiting"))
                                variant = "badge-warning";
                            if (value == "missing_document")
                                variant = "badge-warning";
                            else if (value == "approved")
                                variant = "badge-success";
                            else if (value == "documents_approved")
                                variant = "badge-success";
                            else if (value == "waiting_publish")
                                variant = "badge-success";
                            else if (value == "declined")
                                variant = "badge-danger";
                            else if (value == "student_affairs_declined")
                                variant = "badge-danger";
                            else if (value == "automatic_declined")
                                variant = "badge-danger";

                            return (
                                `<span class="badge ${variant}">${row.status_text}</span>`);
                        },
                    },
                    {
                        label: this.$t("explanation").toUpper(),
                        field: "explanation",
                        hidden: false,
                        formatFn: (value) => {
                            return value || "-"
                        },
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {
                        is_archived:"0"
                    },
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {

        async sendMessage() {
            const valid = await this.$refs.messageFormValid.validate()
            if (!valid) {
                return
            }

            const response = await DoubleMajorApplicationService.studentAffairsSendMessage(this.messageObj)
                .catch(e => this.$toast.error(this.$t('api.' + e.data.message)))

            if (response.data.success) {
                this.$refs.messsageFormModal.$refs.commonModal.hide();
                this.messageObj = {}
            }
            this.$toast.success(this.$t('api.' + response.data.message));
        },
        messageModalOpen(row) {
            this.$refs.messsageFormModal.$refs.commonModal.show();
            this.messageObj = {
                double_major_id: row.id,
                message: null
            }
        },
        filter() {
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, { encode: false }),
            };

            return DoubleMajorApplicationService.studentAffairsIndex(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        getItem(id) {
            DoubleMajorApplicationService.doubleMajorShowGetId(id)
                .then((response) => {
                    this.formData = response.data.data;
                    this.getRows();
                })
        },
        async deleteConfirm() {
            const valid = await this.$refs.rejectFormValid.validate()
            if (!valid) {
                return
            }
            this.$swal
                .fire({
                    text: this.$t("are_you_sure_to_reject"),
                    showCancelButton: true,
                    confirmButtonText: this.$t("yes"),
                    cancelButtonText: this.$t("no"),
                    icon: "question",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        DoubleMajorApplicationService.studentAffairsDecline({
                            double_major_id: this.rejectId,
                            explanation: this.explanation,
                        })
                            .then((response) => {
                                this.getRows();
                                this.$refs.declineFormModal.$refs.commonModal.hide();
                                this.$toast.success(this.$t('api.' + response.data.message));
                            })
                            .catch((e) => {
                                this.showErrors(e);
                            });
                    }
                });
        },
        delete(id) {
            this.rejectId = id;
            this.explanation = "";
            this.$refs.declineFormModal.$refs.commonModal.show();
        },
        async updateShow(row) {
            const res = await this.$swal
                .fire({
                    text: this.$t('application_status_reset_are_you_sure'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                    icon: 'question'
                })

            if (!res.isConfirmed) {
                return
            }

            const data = {
                double_major_id: row.id,
                status: "waiting"
            }
            const response = await DoubleMajorApplicationService.studentAffairsUpdateApplicationStatus(data)
                .catch(e => this.$toast.error(this.$t('api.' + e.data.message)))

            this.getRows();
            this.$toast.success(this.$t('api.' + response.data.message));
        },
        formClear() {
            this.formId = null;
            this.formData = {};
            this.formProcess = null;
        },
        showDetail(id, row) {
            this.formId = id;
            this.formData = row;
            this.formProcess = "detail";
            this.formType = "show";
            this.$refs.detailFormModal.$refs.commonModal.show();
        },
        showDetailSuccess() {
            this.$refs.detailFormModal.$refs.commonModal.hide();
            this.getRows();
            this.formClear();
        },
    },
};
</script>
